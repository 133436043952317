<template>
    <div class="container">
        <div class="banner">
            <div class="wrapper"></div>
        </div>
        <div class="clearfix">
            <div class="side-menu">
                <div class="box1">
                    <div class="about-content clearfix">
                        <div class="img1">
                            <img src="../assets/img/about/dandenlion1.jpg" alt="">
                        </div>
                        <div class="about01">
                            <div class="title"><span>01</span>欢迎来到菩公瑛学苑</div>
                            <div class="desc">
                                欢迎你来到菩公瑛学苑，无论你是无意闯入还是正在搜索选择培训学校，相信我们肯定是有着一定的缘分。“知识”和“能力”是让人产生自信的源泉，相信有自信的人到哪里都是发光发亮的。您能到这里说明我们之间一定同样有着对科技行业共同的兴趣和爱好。所以无论你对未来如何打算，又或许你正打算学习IT技术但无奈在琳琅满目的广告宣传之中反复徘徊，相信你读完这封信后会对菩公瑛学苑有个全新的认识。                        </div>
                        </div>
                    </div>
                </div>
                <div class="box2">
                    <div class="about-content clearfix">
                        <div class="img2">
                            <img src="../assets/img/about/dandenlion2.jpg" alt="">
                        </div>
                        <div class="about02">
                            <div class="title"><span  style="color:white;">02</span>菩公瑛学苑的来历</div>
                            <div class="desc">
                                菩公瑛学苑是由六个合伙人们一起创建的。他们原本都是来自这个行业内的一些优秀的讲师或是从业人员。相聚在一起只是为了能够做一家真正让学生能够学到知识和工作技能的教育机构。每一个前期的初创人员都放弃了自己原本非常好的工作机会和待遇而加入了进来。
    大家没有一个人做过商业类型的运作，毫无商业经验，大家之前的职业都是技术很棒的软件工程师讲师或者是职业规划师。几乎每个人都不知道这家公司会做成什么样子，所以我们最初的打算是以小班的授课模式，竭尽所能的照顾到每个学生，让每个学生能够学的好，学得精，做到到工作岗位可以直接上手工作。
    就这样2022年6月在西安把自己家改成了办公室，全部员工7个人，就这样菩公瑛学苑开张了。我们开始准备学苑的各种教学文案，教学课件和资料，以备更好的迎接学员的到来。“真诚”是发自内心的，“专业”是我们一直坚持的。
    我们要像蒲公英一样把IT的种子洒满祖国的角角落落。真正做成为一家像20年前老师一样令人尊敬的公司。
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box3">
                    <div class="about-content clearfix">
                        <div class="img3">
                            <img src="../assets/img/about/dandenlion3.jpg" alt="">
                        </div>
                        <div class="about03">
                            <div class="title"><span>03</span>菩公瑛学苑名字的由来</div>
                            <div class="desc">
                                菩公瑛其实是从“蒲公英”的名字命名而来，“蒲公英”有很多的象征意义，第一是勇敢，象征着克服对陌生环境的恐慌和未知重新扎根。第二是自信，象征着即便没有鲜艳的颜色和浓郁的香气，也能为大自然增添生机。第三是坚持，象征着即使长在贫瘠的环境中也能坚忍不拔地生长。我们确实成立较晚，但我们的初创人员都是在行业里有着10年甚至20多年沉淀和积累的。我们以“菩公瑛”命名就是因为它有着像蒲公英一样可以用自己的知识和力量源泉给予菩公瑛种子更多的营养知识，使得我们的种子可以勇敢、自信的迈向新的环境，在新的环境坚持自己的梦想。
        十年树木，百年树人，人才培养过程一定不能盲目求快。我们菩公瑛学苑现在虽然渺小，却有大大的梦想。在这个快节奏的时代，我们要做一个稳扎稳打的菩公瑛，给用户提供最优质的服务。使我们的学员像我们的种子一样可以自信勇敢的撒满祖国的角角落落。既然我们是从事职业教育，干脆叫菩公瑛学苑吧。
        这就是我们，可能会带给你更严苛、更赋挑战的人才培养模式，但是也会给你带来更多面对未来职场的勇气、自信和坚持梦想的实力和资本。我们所做的一切，只为成就更好的你！
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box4">
                    <div class="about-content clearfix">
                        <div class="img4">
                            <img src="../assets/img/about/dandenlion4.jpg" alt="">
                        </div>
                        <div class="about04">
                            <div class="title"><span style="color:white;">04</span>我们的愿景</div>
                            <div class="desc">
                                我们希望我们的学员以在菩公瑛学苑学习过为荣，让所有的“IT与互联网”企业不再为人才所困。让IT行业里几乎所有人都知道菩公瑛或者本身就是从菩公瑛学苑走出去的。我们愿做一片绿叶，为创新提供人才，帮助客户和互联网企业不断成长。使我们的IT企业走向世界前列直至遥遥领先。
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box5">
                    <div class="about-content clearfix">
                        <div class="img5">
                            <img src="../assets/img/about/dandenlion5.jpg" alt="">
                        </div>
                        <div class="about05">
                            <div class="title"><span>05</span>菩公瑛学苑的努力与思考</div>
                            <div class="desc">
                                我们需要沉下心来脚踏实地一步步不断学习和提高个人技术能力的前行者，我们需要的不是被所谓的高薪迷惑的人，我们需要的是真正热爱这一行且愿意为之付出努力的追求者。也许你会有疑问菩公瑛学苑有什么不同简要概括来讲我们做了如下几点：
                                <br/><br/>
                                    <p>1.菩公瑛学苑只接纳适合的学员，需要通过对应的考核和评测；</p>
                                    <p>2.严格的管理制度，即便入学后一旦老师发现该学员不适合学习，会立即退费并劝退；</p>
                                    <p>3.免费开通试学课程，学员可根据自己的时间选择进行7天试学课程进行体验。</p>
                                <br/>
                                做以上大量工作的原因是因为我们要筛选掉一些有投机心理，不劳而获等等没有正确心态的学员，虽然这样我们可以获得短暂的经济收益，但最后这些学员很难获得应有的好结果，所以我们要从根源上杜绝学生“花钱买工作”的心态。除了心态不正的原因以外，还有很多同学不可否认是不适合从事IT行业的，所以我们需要通过免费的试听课程来帮助这部分学员了解自己是否真正适合学习IT，而不要因为别人的怂恿而冒然选择.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutC',
}
</script>

<style scoped>
.banner {
    width: 100%px;
    height: 400px;
    background-color: #8EB7E2;
    background-image: url(../assets/img/logo-big.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
}
.container {
    padding-bottom: 40px;
}
.box1, .box3, .box5{
    background-color: #F4F7FA;
}
.box2, .box4{
    background-color: #333147;
}
.about-content {
    width: 1200px;
    margin: 0 auto;
}
.title span {
    font-size: 52px;
    opacity: .2;
    color: #000000;
    margin: 0 10px;
}
.title {
    color: #00B994;
    font-size: 24px;
}
.desc {
    text-indent: 2rem;
    text-align: justify;
    font-size: 12px;
    color: #999;
    margin: 25px auto;
    line-height: 2em;
    padding: 0 50px;
}
.img1, .img3, .img5 {
    width: 50%;
    float: left;
    margin: 60px auto;
}
.img1 img, .img3 img, .img5 img  {
    width: 100%;
    margin: 0 auto;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}
.about01, .about03, .about05 {
    width: 50%;
    float: right;
    margin: 60px auto;
}
.img2,.img4 {
    width: 50%;
    float: right;
    margin: 60px auto;
}
.img2 img,.img4 img {
    width: 100%;
    margin: 0 auto;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(250, 250, 250, 0.4);
}
.about02, .about04 {
    width: 50%;
    float: left;
    margin: 60px auto;
}
</style>