<template>
    <div class="detail">
        <div>【岗位职责】</div>
        <p>1、日常班级管理以及学员维护；</p>
        <p>2、负责组织在读学员各种活动；</p>
        <p>3、维护学生关系，促进转介绍；</p>
        <p>4、开拓就业资源推荐学员就业；</p>
        <p>5、进行职业规划就业技能讲解。</p>
        <div>【岗位要求】</div>
        <p>1、大专及以上学历，专业不限；</p>
        <p>2、沟通表达良好，有演讲能力；</p>
        <p>3、性格活泼能和学生打成一片；</p>
        <p>4、有IT同行经验，有就业资源；</p>
        <p>5、Office.WPS等办公软件熟练。</p>
        <div>【薪资福利】</div>
        <p>1、薪资：6-11k；</p>
        <p>2、入职缴纳五险，学员就业有奖金，挖转介有提成</p>
        <p>3、年底奖金；</p>
        <p>4、简单团结的团队文化，年度体检，带薪休假，带薪年假，节日福利等；</p>
        <p>5、工作时间9:00-18:00 （12:00-14:00休息）；</p>
    </div>
</template>

<script>
export default {
    name: 'HumanResourceTestingEngineerC',
}
</script>

<style scoped>
.detail div {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin: 20px auto;
}
.detail p {
    line-height: 1.5em;
    margin: 10px auto;
}
</style>