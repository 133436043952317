<template>
    <div></div>
</template>

<script>
export default {
    name: 'HomeTeacherTab',
}
</script>

<style>

</style>