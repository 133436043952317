<template>
  <div id="app">
    <div class="nav-container">
        <HeaderC/></div>
        <div><router-view></router-view></div>
        <div><FooterC/>
    </div>
  </div>
</template>

<script>
import HeaderC from './components/HeaderC.vue';
import FooterC from './components/FooterC.vue';

export default {
    name: "App",
    components: { HeaderC, FooterC }
}
</script>

<style>
.clearfix::before,
.clearfix::after {
    content: '';
    display: table;
    clear: both;
  }
body {
  font: 14px Helvetica Neue,Helvetica,Arial,Microsoft Yahei,Hiragino Sans GB,Heiti SC,WenQuanYi Micro Hei,sans-serif;
  min-width: 1200px;
}
.nav-container {
    background-color: #8EB7E2;
}
</style>
