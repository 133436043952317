<template>
    <div class="container">
        <div class="banner">
            <img src="../assets/img/teacher/teacher-banner.jpg" alt="">
        </div>
        <div class="content clearfix">
            <div class="left-side">
            </div>
            <ul class="right-side">
                <li>
                    <img src="../assets/img/teacher/刘丰.png" alt="">
                    <h3 class="item-nickname">刘老师</h3>
                    <p class="item-tit">金牌讲师</p>
                    <p class="item-des">　西安交通大学毕业，26年软件研发经验。前后端开发专家.软件架构专家，擅长Web/Android/ios/Linux系统等各类型项目的开发。曾就职于曾在澳洲Frank-Small管理咨询公司担任产品经理，法国Sofres公司担任高级数据分析师，美国TNS全球咨询公司担任资深软件架构师、日本Intage公司软件开发总监等职务</p>
                </li>
                <li>
                    <img src="../assets/img/teacher/gaoliang.png" alt="">
                    <h3 class="item-nickname">高老师</h3>
                    <p class="item-tit">金牌讲师</p>
                    <p class="item-des">　专注于软件开发领域15年，曾就职于多个国际一流IT企业，从事软件开发、研发管理、敏捷项目管理、质量管理以及过程改进工作，对软件质量管理、测试管理、大数据和自动化、敏捷项目管理等有深入的了解和丰富的实践。国际软件测试高级工程师，ISTQB认证会员。多年培训经验，曾主持:广州某集团酒店管理平台项目/北京某集团商城移动自动化项目/北京某集团互联网金融平台自动化项目。</p>
                </li>
                <li>
                    <img src="../assets/img/teacher/吕鹏.png" alt="">
                    <h3 class="item-nickname">吕老师</h3>
                    <p class="item-tit">金牌讲师</p>
                    <p class="item-des">　多年丰富经验的资深C++程序员。自他投身编程行业以来，在C++编程领域积累了丰富的经验和深厚的造诣。
                    参与了众多大型项目的开发，涉及领域广泛，包括系统软件、网络应用、嵌入式系统等多个方向。在这些项目中，他负责设计核心模块、编写关键代码，并成功解决了多个技术难题，为项目的顺利推进和成功交付做出了重要贡献。</p>
                </li>
                <li>
                       <img src="../assets/img/teacher/孙毅.png" alt="">
                    <h3 class="item-nickname">孙老师</h3>
                    <p class="item-tit">金牌讲师</p>
                    <p class="item-des">　12年软件开发、软件测试与技术培训经验，历任高级软件工程师、项目经理、测试经理、系统架构师，多次主持开发国家级项目，涉及电信、金融、物流行业;屡次应邀至软件企业和著名高校授课。</p>
                </li>
                <li>
                    <img src="../assets/img/teacher/李建海.png" alt="">
                    <h3 class="item-nickname">李老师</h3>
                    <p class="item-tit">金牌讲师</p>
                    <p class="item-des">　拥有16年C++编程经验的资深工程师，精通面向对象编程、多线程等技术，具备深厚的算法和数据结构基础，团队协作能力强，为多个大型项目成功交付做出重要贡献。</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TeacherC',
}
</script>

<style scoped>
.banner {
    width: 100%px;
    height: 400px;
}
.banner img {
    position: relative;
    top: -75px;
    width: 100%;
    height: 475px;
}
.left-side {
    float: left;
    width: 30%;
    height:600px;
    /* background-color: #8EB7E2; */
    background-image: url('../assets/img/teacher/teacher.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
}
.right-side {
    float: right;
    width: 70%;
}
.right-side li {
    position: relative;
    width: 100%;
    height: 304px;
    box-shadow: 0px 2px 7px 0px rgb(0 0 0 / 4%);
    border: solid 1px #f0f0f0;
    text-align: center;
    box-sizing: border-box;
    margin: 85px 14px 20px 2px;
}
.right-side li:hover {
    border: solid 1px #78bff2;
}
.right-side li img {
    position: absolute;
    top: -66px;
    left: 50%;
    transform: translateX(-50%);
    width: 132px;
    height: 132px;
    border-radius: 50%;
    background: #929eb8;
}

.right-side li .item-nickname {
    padding-top: 88px;
    font-family: SourceHanSansCN-Bold;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 1;
}
.right-side li .item-tit{
    position: relative;
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    margin: 9px auto 30px auto;
    color: #007fdd;
}
.right-side li p{
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    line-height: 21px;
    color: #999999;
    margin: 0 auto;
    text-align: left;
    padding: 0 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 63px;
}
.item-des {
    float: right;
}
</style>
