<template>
    <div class="footer">
        <div class="footer-up clearfix">
            <div class="content">
                <div class="col">
                    <div class="item">
                        <h4 class="contact">联系方式</h4>
                        <h5 class="h5">24小时咨询热线：</h5>
                        <h2 class="mobile">137 0912 6776</h2>
                        <br>
                        <h5 class="h5">微信联系方式：</h5>
                        <h4 class="h4">小蒲老师</h4>
                        <div class="code">
                            <img src="../assets/img/wechat-code.png">
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="item">
                        <h4 class="service">业务范围</h4>
                        <ul class="foot-ul">
                            <li><a href="#">就业培训</a></li>
                            <li><a href="#">企业外包</a></li>
                            <li><a href="#">职业规划</a></li>
                            <li><a href="#">高级猎聘</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col">
                    <div class="item">
                        <h4 class="course">课程体系</h4>
                        <ul class="course-ul">
                            <li><a href="#">C++全栈开发</a></li>
                            <li><a href="#">全栈测试开发</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-down">
            <div class="copyright">
                <div>菩公瑛学苑 - 专注软件测试！( <a href="https://beian.miit.gov.cn/" target="_blank">陕ICP备2023003137号-1</a> )
                </div>
                <div>Copyright 2023-2030 ©西安恩晟教育科技有限公司</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterC'
}
</script>

<style>
.footer-up {
    width: 100%;
    /* height: 320px; */
    background-color: #276DC3;
}
.footer-down {
    width: 100%;
    height: 80px;
    background-color: #143257;
}
.content {
    width: 1200px;
    margin: 0 auto;
    color: #fff;
    font-size: 16px;
    text-align: left;
    font-family: "Microsoft YaHei";
    font-weight: 100;
}
.content .col {
    float: left;
    margin: 50px 100px;
}
.content .col h2 {
    font-size: 30px;
    font-weight: normal;
    opacity: 0.9;
}
.content .col h4 {
    font-size: 18px;
    font-weight: normal;
    opacity: 0.7;
}
.content .col h5 {
    font-size: 14px;
    font-weight: normal;
    opacity: 0.3;
}
.contact {
    margin-bottom: 20px;
}
.mobile, .h4, .h5 {
    margin-bottom: 10px;
}
.h4 {
    display: inline-block;
}
.code img {
    display: inline-block;
    width: 150px;
}
.copyright {
    width: 1200px;
    margin: 0 auto;
    color: #AFAFAF;
    text-align: center;
}
.copyright div {
    padding-top: 15px;
}
.service, .course {
    margin-bottom: 30px;
}
.foot-ul li {
    margin-bottom: 20px;
}
.foot-ul li a {
    color: #fff;
    opacity: 0.3;
}
.foot-ul li a:hover {
    opacity: 0.7;
}
.course-ul li {
    margin-bottom: 15px;
}
.course-ul li a {
    color: #fff;
    opacity: 0.3;
}
.course-ul li a:hover {
    opacity: 0.7;
}
.copyright div a {
    /*height: 20px;*/
    /*line-height: 20px;*/
    color: #AFAFAF;
    /*overflow: hidden;*/
}
.copyright div a:hover {
    color: white;
}
</style>
