<template>
        <div class="detail">
        <div>【岗位职责】</div>
        <p>1、无需开发客户，依托公司获取客户资源；.</p>
        <p>2、电话邀约意向客户上门了解学习课程；</p>
        <p>3、接待到访客户，为客户提供专业的课程咨询服务并促成报名；</p>
        <p>4、为客户制定合理的课程，协助客户完成报名手续；</p>
        <p>5、准确收集、统计、整理咨询数据，挖掘有效顾客信息。</p>
        <div>【岗位要求】</div>
        <p>1、大专及以上学历，专业不限；</p>
        <p>2、沟通表达良好；</p>
        <p>3、性格活泼能和学生打成一片；</p>
        <p>4、有IT同行经验，有就业资源；</p>
        <p>5、Office.WPS等办公软件熟练。</p>
        <div>【薪资福利】</div>
        <p>1、薪资：底新+补贴+提成=12K+；</p>
        <p>2、入职缴纳五险；</p>
        <p>3、年底奖金；</p>
        <p>4、转正后每月一次底薪调整/晋升机会；</p>
        <p>5、简单团结的团队文化，年度体检，带薪休假，带薪年假，节日福利等；</p>
        <p>6、工作时间9:00-18:00 （12:00-14:00休息）；</p>
    </div>
</template>

<script>
export default {
    name: 'HumanResourceTestingTernimalC',
}
</script>

<style scoped>
.detail div {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin: 20px auto;
}
.detail p {
    line-height: 1.5em;
    margin: 10px auto;
}
</style>