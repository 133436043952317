<template>
    <div class="advantage-tab">
        <div class="advantage-main clearfix">
            <h1 class="title">专业C++开发，打造最佳课程</h1>
            <ul class="points">
                <li class="point-one">
                    <i class="fa fa-university"></i>
                    <h3>深耕C++开发</h3>
                    <p>全方位打造C++专业课程，倾力推出最佳的C++课程，保证未来就业</p>
                </li>
                <li class="point-two">
                    <i class="fa fa-id-card"></i>
                    <h3>免费试学随时可退</h3>
                    <p>免费试听公开课，满意后再缴费</p>
                </li>
                <li class="point-one">
                    <i class="fa fa-calendar"></i>
                    <h3>严格管理</h3>
                    <p>高效学习与管理，一周五天+晚自习，周考、阶段考、项目答辩贯穿全程</p>
                </li>
                <li class="point-two">
                    <i class="fa fa-shield"></i>
                    <h3>课程持续更新</h3>
                    <p>时刻关注技术发展动态，保证学员知识体系紧跟市场前沿方向</p>
                </li>
                <li class="point-one">
                    <i class="fa fa-database"></i>
                    <h3>全程项目驱动</h3>
                    <p>始终坚持以项目实战为主架构，注重学员动手能力，项目涵盖范围宽</p>
                </li>
                <li class="point-two">
                    <i class="fa fa-file-text"></i>
                    <h3>就业保障</h3>
                    <p>以就业为导向，多渠道+多形式保障学员就业</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeAdvantageTab',
}
</script>

<style scoped>
/* 菩公瑛优势 */
.advantage-tab {
    background-color: #fff;
    padding: 150px 0;
}

.points .point-one{
    float: left;
    width: 30%;
    height: 180px;
    margin: 30px 5% 10px 15%;
    padding: 30px;
}
.points .point-one {
    text-align: right;
}
.points .point-one p {
    text-align: left;
}
.points .point-two{
    float: left;
    width: 30%;
    height: 180px;
    margin: 30px 15% 10px 5%;
    padding: 30px;
}
.points li i {
    font-size: 40px;
    margin-bottom: 20px;
    color: #FE771B;
}
.points li h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}
.points li p {
    font-size: 14px;
    margin-bottom: 20px;
}
.points li {
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(142, 183, 226, 0.5);
}
.points li:hover {
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}
.advantage-main{
    width: 1200px;
    margin: 0 auto;

}
.title {
    width: 1200px;
    padding: 10px 0;
    margin: 0 auto;
    padding-bottom: 80px;
    color: #616175;
    font-size: 36px;
    font-weight: bold;
    line-height: 48px;
    text-align: center;
}
</style>