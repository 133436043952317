<template>
   <div class="tools-tab">
        <div class="tools-main clearfix">
            <h1 class="title">测试工具</h1>
            <ul class="tools clearfix">
                <li class="tool"><img src="../assets/img/home/tools/python-logo.png" alt=""><span class="desc">Python</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img01.png" alt=""><span class="desc">Java</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img02.png" alt=""><span class="desc">MySQL</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img03.png" alt=""><span class="desc">Oracle</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img04.png" alt=""><span class="desc">SVN</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img05.png" alt=""><span class="desc">K8S</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img06.png" alt=""><span class="desc">Git</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img08.png" alt=""><span class="desc">Selenium</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img09.png" alt=""><span class="desc">HP HUT</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img10.png" alt=""><span class="desc">UIAutomator</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img11.png" alt=""><span class="desc">Postman</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img12.png" alt=""><span class="desc">SoapUI</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img13.png" alt=""><span class="desc">Fiddler</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img14.png" alt=""><span class="desc">Appium</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img15.png" alt=""><span class="desc">Robotium</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img16.png" alt=""><span class="desc">Monkey</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img17.png" alt=""><span class="desc">Junit</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img18.png" alt=""><span class="desc">TestNG</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img19.png" alt=""><span class="desc">Jekins</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img20.png" alt=""><span class="desc">Badboy</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img21.png" alt=""><span class="desc">LR</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img22.png" alt=""><span class="desc">JIRA</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img23.png" alt=""><span class="desc">禅道</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img25.png" alt=""><span class="desc">JMeter</span></li>
                <li class="tool"><img src="../assets/img/home/tools/sec0_img26.png" alt=""><span class="desc">RF</span></li>
            </ul>
        </div>
   </div>
</template>

<script>
export default {
    name: 'HomeToolsTab',
}
</script>

<style scoped>
.tools-tab {
    background-color: #F7F7F7;
    padding: 150px 0;
}
.tools-main {
    width: 1200px;
    margin: 0 auto;
}
.title {
    width: 1200px;
    padding: 10px 0;
    margin: 0 auto;
    padding-bottom: 50px;
    color: #616175;
    font-size: 36px;
    font-weight: bold;
    line-height: 48px;
    text-align: center;
}
.tools {
    width: 750px;
    margin: 20px auto;
}
.tools .tool {
    float: left;
    margin: 20px;
}
.tool img,.desc {
    display: block;
    margin: 5px auto;
    text-align: center;
}
.tool img {
    border-radius: 10px;
}
.desc {
    width: 110px;
    margin-top: 20px;
}
.tool img {
    box-shadow: 0 0 20px rgba(142, 183, 226, 0.4);
}
.tool img:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}
.tool img:hover + .desc {
    font-weight: bold;
    color: #0671CD;
}
</style>
