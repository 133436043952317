<template>
    <div class="detail">
        <div>【岗位职责】</div>
        <p>1、负责软件测试课程体系中的理论和实践课教学工作，按进度完成教学任务；</p>
        <p>2、带领学员参加项目实践并进行考核；。</p>
        <p>3、参与学术交流与研讨，改进教学方法，完善教学体系。</p>
        <div>【岗位要求】</div>
        <p>1、必须具备8年及以上软件测试从业经验，本科及以上学历，名牌大学科班专业出身优先；</p>
        <p>2、熟悉软件工程、产品结构及质量控制理论，熟悉相关测试技术和工具，且熟悉缺陷跟踪工具；</p>
        <p>3、具备软件测试平台搭建能力，熟练编写测试脚本和使用测试工具；</p>
        <p>4、熟悉PC端和移动端测试工具，有Ios、Android电商APP测试经验；</p>
        <p>5、热爱教育事业，具有强烈的责任心和高度的团队意识；</p>
        <p>6、具有较强的沟通表达能力和分析解决问题的能力。</p>
        <div>【薪资福利】</div>
        <p>1、薪资：15-25k；</p>
        <p>2、入职缴纳五险；</p>
        <p>3、年底奖金；</p>
        <p>4、简单团结的团队文化，年度体检，带薪休假，带薪年假，节日福利等；</p>
        <p>5、工作时间9:00-18:00 （12:00-14:00休息）；</p>
    </div>
</template>

<script>
export default {
    name: 'HumanResourceTestingTeacherC',
}
</script>

<style scoped>
.detail div {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin: 20px auto;
}
.detail p {
    line-height: 1.5em;
    margin: 10px auto;
}
</style>