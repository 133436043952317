<template>
    <div class="projects-tab">
        <div class="projects-main clearfix">
            <h1 class="title">全程项目驱动</h1>
            <ul class="projects clearfix">
                <li v-for="(project, index) in projects" :key="index" 
                    :class="{'show-project': project.isActive, 'hide-project': !project.isActive}">
                    <h3><i class="fa fa-bars"></i>{{project.title}}</h3>
                    <div>
                        <img :src="project.url" alt="">
                    </div>
                </li>
                <div class="pagination">
                    <span class="swiper-pagination-switch" v-for="(span, index) in spans" :key="index" 
                        :class="{'swiper-active-switch':span.isActive}" @click="active_span(index)"></span>
                </div>
            </ul>
        </div>
   </div>
</template>

<script>
export default {
    name: 'HomeProjectTab',
    data(){
        return {
            projects: [
                {title: '实战项目-OA办公系统', url: require('../assets/img/home/projects/78OA.png'), isActive:true},
                {title: '实战项目-协同系统', url: require('../assets/img/home/projects/ranzhi.png'), isActive:false},
                {title: '实战项目-CRM系统', url: require('../assets/img/home/projects/crm.png'), isActive:false},
                {title: '实战项目-商城系统', url: require('../assets/img/home/projects/tpshop.jpeg'), isActive:false},
            ],
            spans: [
                {isActive:true},
                {isActive:false},
                {isActive:false},
                {isActive:false},
            ],
            timer:''
        }
    },
    methods: {
        active_span(index){
            // 关闭定时器
            window.clearInterval(this.timer)
            // 激活指定项目
            this.projects.forEach((project) => { project.isActive = false; });
            this.projects[index].isActive = true;
            this.spans.forEach((span) => { span.isActive = false; });
            this.spans[index].isActive = true;
        }
    },
    mounted() {
        let index = 0;
        this.timer = window.setInterval(() => {
            index++;
            this.projects.forEach((project) => { project.isActive = false; });
            this.projects[index % this.projects.length].isActive = true;
            this.spans.forEach((span) => { span.isActive = false; });
            this.spans[index % this.spans.length].isActive = true;
        }, 2000);
    },
}
</script>

<style scoped>
.projects-tab {
    background-color: #FFF;
    padding: 150px 0;
}
.projects-main {
    width: 1200px;
    margin: 0 auto;
}
.title {
    width: 1200px;
    padding: 10px 0;
    margin: 0 auto;
    padding-bottom: 50px;
    color: #616175;
    font-size: 36px;
    font-weight: bold;
    line-height: 48px;
    text-align: center;
}
.projects {
    width: 950px;
    height: 570px;
    margin: 0 auto;
    border-radius: 20px;
    background-color: #8EB7E2;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}
.hide-project {
    display: none;
}
.show-project {
    display: block;
}
.projects i {
    color: #FE771B;
    margin-right: 10px;
}
.projects h3 {
    font-size: 30px;
    font-weight: bold;
    padding: 20px;
    color: #fff;
}
.projects img {
    display: block;
    /* width: 90%; */
    height: 400px;
    margin: 20px auto;
    /* border: 10px solid aqua; */
    border-radius: 20px;
}
.pagination {
    text-align: center;
}
.swiper-pagination-switch {
    display: inline-block;
    width: 50px;
    height: 8px;
    background: #555;
    margin: 0 5px;
    opacity: 0.8;
    cursor: pointer;
}
.swiper-active-switch {
    background: #fff;
}
</style>