<template>
    <div class="detail">
        <div>【岗位职责】</div>
        <p>1、负责C++课程体系中的理论和实践课教学工作，按进度完成教学任务；</p>
        <p>2、带领学员参加项目实践并进行考核；。</p>
        <p>3、参与学术交流与研讨，改进教学方法，完善教学体系。</p>
        <div>【岗位要求】</div>
        <p>1、统招公办高校本科及以上学历(计算机、软件工程及相关专业者优先)，3年以上开发经验；</p>
        <p>2、掌握C/C++语言，熟悉STL/Boost等标准/准标准库的基本使用，有源码阅读经验者优先；</p>
        <p>3、掌握常见数据结构与算法，有相关算法竞赛经验者优先；</p>
        <p>4、具有C++相关开源项目(Libevent、Redis、Memched、Nginx 等)的使用经验，具有源码阅读经历者优先；</p>
        <p>5、熟悉Linux操作系统，具有Linux上进行C/C++开发，部署上线的全过程认知；</p>
        <p>6、熟悉操作系统原理，精通网络原理，具有Linux系统编程与网络编程经验；</p>
        <p>7、熟练掌握MySQL数据库，掌握MySQL核心原理，有MongoDB、Redis等NoSQL的应用经验；</p>
        <p>8、了解HTML/CSS/JS，具有前后端交互的整体认知；</p>
        <p>9、有较强的表达沟通能力，有良好的逻辑思维能力，思维开放，能接受学生与其他老师的教学建议，具有良好的服务意识，善于总结，善于写作，有长期维护技术博客者优先；</p>
        <div>【薪资福利】</div>
        <p>1、薪资：15-25k；</p>
        <p>2、入职缴纳五险；</p>
        <p>3、年底奖金；</p>
        <p>4、简单团结的团队文化，年度体检，带薪休假，带薪年假，节日福利等；</p>
        <p>5、工作时间9:00-18:00 （12:00-14:00休息）；</p>
    </div>
</template>

<script>
export default {
    name: 'HumanResourceCppTeacherC',
}
</script>

<style scoped>
.detail div {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin: 20px auto;
}
.detail p {
    line-height: 1.5em;
    margin: 10px auto;
}
</style>