<template>
    <nav class="menu-list">
        <div>
            <router-link to="/home">
                <img src="../assets/img/logo-pgy-white.png">
            </router-link>
        </div>
        <div class="wrapper" v-for="(item,index) in menuList" :key="index">
            <div><router-link class="item" active-class="active" :to="item.to">{{item.title}}</router-link></div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'HeaderC',
    data() {
        return {
            activeIndex: 0,
            menuList: [
                {to: '/home', title: 'C/C++'},
                {to: '/hometest', title: '测试开发'},
                // {to: '/course', title: '最新课程'},
                {to: '/teacher', title: '师资团队'},
                {to: '/free_course', title: '免费课程'},
                {to: '/student', title: '校区环境'},
                {to: '/about', title: '关于我们'},
                {to: '/hr', title: '人才招聘'},
            ]
        };
    },
    methods: {
    }
}
</script>

<style scoped>
.menu-list {
    width: 1200px;
    height: 75px;
    margin: 0 auto;
    position: relative;
    z-index: 99;
}
.menu-list img {
    height: 72px;
    display: block;
    float: left;
    margin-right: 200px;
}
.item {
    width: 80px;
    height: 40px;
    line-height: 40px;
    margin-top: 16px;
    margin-right: 30px;
    display: block;
    float: left;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}
.item:hover, .active {
    color: #ff3e2d;
    border-bottom: 3px solid #F57015;
}
</style>
