<template>
    <div>Course</div>
</template>

<script>
export default {
    name: 'CourseC'
}
</script>

<style>

</style>