<template>
    <div class="analysis-tab">
        <div class="analysis-main clearfix">
            <h1 class="title">学软件测试，未来有保障</h1>
            <ul class="nav-pills" ref="nav_pills">
                <li data-sub="0" v-for="(item, index) in pills" :key="index" @click="activeItem(index)">
                    <div class="pills-one" :class="{'pills-one-show': item.isActive}">
                        <span class="num"><i :class="item.num"></i></span>
                        <div class="desc">
                            <p>{{item.p1}}</p>
                            <p>{{item.p2}}</p>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="nav-content">
                <div v-for="(item, index) in nav_items" :key="index" >
                    <img :src="item.url" :alt="item.alt" :class="{'nav-item':!item.isActive, 'nav-show':item.isActive}">
                </div>
            </div>
            <p class="datasource">数据来源：职友集</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeAnalysisTab',
    data() {
        return {
            pills: [
                { num: "fa fa-briefcase", p1: "岗位需求大，就业跳槽快", p2: "中国软件测试人才缺口40万左右", isActive: true },
                { num: "fa fa-cny", p1: "岗位选择多，薪资报酬高", p2: "平均薪资达到12k+以上", isActive: false },
                { num: "fa fa-rocket", p1: "发展方向多，晋升快速", p2: "软件测试后期可选职业方向非常丰富，总能找到适合你的一种", isActive: false },
                // {num:'04', p1:"市场需求量大，招聘需求95227人/月", p2:"市场上对软件测试岗位需求极大，急需人才涌入", isActive:false},
            ],
            nav_items: [
                { url: require("@/assets/img/home/xuqiuda.png"), alt: "测试薪资走势", isActive: true },
                { url: require("@/assets/img/home/gangweiduo.png"), alt: "测试行业发展", isActive: false },
                { url: require("@/assets/img/home/fazhanhao.png"), alt: "测试发展方向", isActive: false },
                // {url: require("@/assets/img/home/TEST-analysis4.svg"), alt:"测试人才需求", isActive:false},
            ],
        };
    },
    methods: {
        activeItem(index) {
            this.pills.forEach((pill) => { pill.isActive = false; });
            this.pills[index].isActive = true;
            this.nav_items.forEach((item) => { item.isActive = false; });
            this.nav_items[index].isActive = true;
        }
    },
    mounted() {
        let index = 0;
        window.setInterval(() => {
            index++;
            this.pills.forEach((pill) => { pill.isActive = false; });
            this.pills[index % this.pills.length].isActive = true;
            this.nav_items.forEach((item) => { item.isActive = false; });
            this.nav_items[index % this.nav_items.length].isActive = true;
        }, 2000);
    },
}
</script>

<style scoped>
/* 测开行业前景 */
.analysis-tab {
    /* background-color: #ECF0F3; */
    background-color: #f7f7f7;
    padding: 80px 0;
}
.analysis-main {
    width: 1200px;
    margin: 0 auto;

}
.nav-pills {
    float: left;
    height: 506px;
    padding-top: 60px;
}
.nav-pills li {
    width: 486px;
    cursor: pointer;
    padding: 10px 0;
    border-bottom: 1px solid #E4E7E9;
}
.pills-one {
    height: 68px;
    padding: 10px;
    border-radius:20px;
}
.pills-one:hover, .pills-one-show{
    background-color: #e7e8e9;
}
.num {
    float: left;
    display: inline-block;
    font-size: 36px;
    line-height: 48px;
    color: #616175;
    margin-right: 20px;
    font-family: DIN-Bold;
    text-align: center;
}
.num i {
    width: 36px;
    color: #FE771B;
}
.desc {
    float: left;
    display: inline-block;
}
.desc p:first-child {
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 10px;
}
.desc p:last-child {
    font-size: 12px;
}
.nav-content {
    height: 400px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 80px;
}
.datasource {
    text-align: right;
    margin-right: 50px;
}
.nav-item  {
    display: none;
}
.nav-show {
    display: block;
    width: 600px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}
.title {
    width: 1200px;
    padding: 10px 0;
    margin: 0 auto;
    padding-bottom: 80px;
    color: #616175;
    font-size: 36px;
    font-weight: bold;
    line-height: 48px;
    text-align: center;
}
</style>