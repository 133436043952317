import Vue from 'vue'
import App from './App.vue'

// 引入axios
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios)

// 引入element-ui
import './plugins/element.js'

// 引入axios
import './plugins/axios.js'

// 导入全局样式
import './assets/css/global.css'
// 重置所有样式
import './assets/css/reset.css'

// 引入font-awesome
import 'font-awesome/css/font-awesome.min.css'

// 引入路由
import router from './router'

Vue.config.productionTip = false


new Vue({
  render: h => h(App),
  router: router,
}).$mount('#app')
