import Vue from 'vue'

/* axios */

// 引入axios
import axios from 'axios'

// 进行全局挂载
Vue.prototype.$http = axios

// 设置请求根路径
axios.defaults.baseURL = 'http://127.0.0.1:8888/api/private/v1/'