<template>
  <div class="container">
        <div class="banner">
            <div class="wrapper">
            </div>
        </div>
        <div class="content clearfix" >
           <div class="course_stage_item">
               <h2 class="ksd-title-position-h2" index="1" id="ksd-title-position-1">
                    <span class="cro_icon1">1</span>
                    <span>Python</span>
               </h2>
               <div class="course-item" v-for="(course,index) in courseList" :key="index">
                       <a target="_blank" :href="'https://www.bilibili.com/video/BV1kR4y1X7gY?p='+course.href" :title="course.title" class="course__img" @click="add(index)">
                            <div class="course-img ">
                                <img height="140" class="bgimg" width="100%" :src="course.img" data-original="/assert/course/c1/01.jpg" style="">
                                <span class="num">{{index+1}}</span>
                                <span class="stimer">
                                     <i class="iconfont icon-bilibili-line"></i>
                                    {{course.time}}
                                </span>
                            </div>
                       </a>
                       <div class="course-content">
                           <a target="_blank" :href="'https://www.bilibili.com/video/BV1kR4y1X7gY?p='+course.href" :title="course.title" class="course__img" @click="add(index)">
                                <h3 :title="course.title" class="course__title">{{course.title}}</h3>
                                <p class="course__author">{{course.desc}}</p>
                           </a>
                           <div class="course-price-wrap">
                               <a target="_blank" :href="'https://www.bilibili.com/video/BV1kR4y1X7gY?p='+course.href" :title="course.title" class="course__img" @click="add(index)"></a>
                               <a href="/" class="c999">
                                   <img src="../assets/img/logo_pure.png" class="ksd-avatar3" alt="">
                               </a>
                               <a href="/" class="c999 pl2">菩公瑛学苑</a>
                                    <span class="course__btn">
                                        <svg class="bi bi-people-fill bipeople" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"></path>
                                        </svg>
                                        {{counts[index].count}}人次学过
                                    </span>
                           </div>
                       </div>
                   </div>
           </div>
        </div>
  </div>
</template>

<script>
export default {
    name: 'StudentC',
    data() {
        return {
            activeIndex: 0,
            counts: [],
            courseList: [
                {title: 'Python入门', desc: '做好扶稳，准备发车！', time: '00:38:02', href: "1", img: require('../assets/img/course/01.png')},
                {title: 'Python基础', desc: '基础决定上层建筑！', time: '05:07:20', href: "3", img: require('../assets/img/course/02.png')},
                {title: 'Python函数', desc: '面向过程编程的核心', time: '03:40:19', href: "12", img: require('../assets/img/course/03.png')},
                {title: 'Python高级特性', desc: '代码不是越多越好，而是越少越好！', time: '02:29:31', href: "20", img: require('../assets/img/course/04.png')},
                {title: 'Python函数式编程', desc: '高度抽象的编程范式', time: '02:26:22', href: "24", img: require('../assets/img/course/05.png')},
                {title: 'Python模块', desc: '高效组织你的代码！', time: '00:35:50', href: "29", img: require('../assets/img/course/06.png')},
                {title: 'Python面向对象编程', desc: '进阶OOP！', time: '03:03:19', href: "30", img: require('../assets/img/course/07.png')},
                {title: 'Python面向对象高级编程', desc: '拥抱面向对象的高级特性！', time: '02:25:22', href: "36", img: require('../assets/img/course/08.png')},
                {title: 'Python错误调试', desc: '不要在为程序出错而烦恼！', time: '01:06:48', href: "44", img: require('../assets/img/course/09.png')},
                {title: 'PythonIO编程', desc: '掌握读写的精髓！', time: '02:01:21', href: "48", img: require('../assets/img/course/10.png')},
                {title: 'Python进程和线程', desc: '初步了解并发！', time: '01:16:56', href: "51", img: require('../assets/img/course/11.png')},
                {title: 'Python正则表达式', desc: '字符串处理的利器！', time: '01:36:19', href: "54", img: require('../assets/img/course/12.png')},
                {title: 'Python常用内置模块', desc: '日常编程必备知识！', time: '01:44:58', href: "56", img: require('../assets/img/course/13.png')},
                {title: 'Python常用第三方模块', desc: '日常编程必备知识！', time: '01:05:30', href: "61", img: require('../assets/img/course/14.png')},
                {title: 'Python图形界面编程', desc: '图形界面必备模块PyQT5！', time: '00:50:42', href: "63", img: require('../assets/img/course/15.png')},
                {title: 'Python访问数据库', desc: '使用Python来操作数据库！', time: '00:44:30', href: "64", img: require('../assets/img/course/16.png')},
                {title: 'Python Web', desc: '使用Flask实现一个简单的Web项目！', time: '00:53:12', href: "65", img: require('../assets/img/course/17.png')},
            ]
        };
    },
    created() {
        this.fetchData()
    },
    methods: {
        add(index){
            const vm = this
            vm.counts[index].count++
            this.axios({
                method: 'POST',
                url: 'http://124.220.151.95:8000/pgy_gate/add_count?id='+vm.counts[index].id+'&count='+vm.counts[index].count
            })
        },
        fetchData(){
            const vm = this
            this.axios({
                method: 'GET',
                url: 'http://124.220.151.95:8000/pgy_gate/video_counts'
            }).then(function (resp) {
                vm.counts = resp.data
            })
        }
    }
}
</script>

<style scoped>
.banner {
    width: 100%;
    height: 400px;
    background-color: #8EB7E2;
    background-image: url(../assets/img/course/course.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
}
.course_stage_item {
    position: relative;
    margin-top: 50px;
    margin-bottom: 10px;
    padding-top: 4px;
}
.cro_icon1 {
    width: 30px;
    height: 30px;
    background-color: #1E9FFF;
    position: absolute;
    left: -41px;
    top: 7px;
    border-radius: 50%;
    z-index: 3;
    text-align: center;
    line-height: 30px;
    font-size: 18px;
    color: #fff;
}
.course_stage_item h2 {
    color: #1E9FFF;
    font-size: 22px;
    padding-top: 6px;
    font-weight: bold;
}
.course-item {
    border-radius: 4px;
    max-width: 310px;
    margin: 15px 10px 5px;
    -webkit-transition: all .3s;
    border: 1px solid #eee;
    padding: 10px 12px;
    transition: all .3s;
    background: #ffffff;
    position: relative;
    display: inline-block;
}
.course-img {
    position: relative;
}
.course-img:after {
    position: absolute;
    bottom: -2px;
    left: 30px;
    content: "";
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 13px solid #fff;
}
.bgimg {
    transition: 300ms linear;
    height: 140px;
    border-radius: 6px;
}
.course__img:hover img {
    transform: scale(1.03);
}
.num {
    color: #fff;
    background: rgba(3,18,60,0.42);
    display: inline-block;
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-weight: 600;
}
.stimer {
    color: #fff;
    background: rgba(3,18,60,0.42);
    display: inline-block;
    text-align: center;
    position: absolute;
    bottom: 0px;
    right: 0;
    font-weight: 400;
    font-size: 12px;
    border-radius: 2px;
    padding: 3px 6px;
}
.course-content {
    border-radius: 0 0 4px 4px;
    padding: 12px 0px 16px;
    border-top: transparent;
}
.course__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #233d63;
    -webkit-transition: all .3s;
    transition: all .3s;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 45px;
}
.course__title:hover {
    color: #1E9FFF;
}
.ksd-avatar3 {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    vertical-align: baseline;
    top: 1px;
    position: relative;
    left: -2px;
}
.course-price-wrap {
    display: -ms-flex;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 20px;
}
.course-content .course__author {
    line-height: 20px;
    margin-bottom: 10px;
    font-size: 13px;
    color: #999;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-top: 5px;
}
.pl2 {
    padding-left: 2px;
}
.c999 {
    color: #999;
}
.c999:hover {
    color: #34495e;
}
.course__btn {
    font-family: PingFangSC-Regular;
    margin-left: auto;
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    background: #1E9FFF;
    border-radius: 4px;
    cursor: pointer;
}
.course__btn:hover {
    text-decoration: none;
    background: #28d899;
}
</style>
