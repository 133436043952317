<template>
    <div class="container">
        <div class="banner">
            <img src="../assets/img/humanResource/recruit2.jpg" alt="">
        </div>
        <div class="main-content clearfix">
            <div class="left-side">
                <img src="../assets/img/humanResource/求贤.jpeg" alt="">
            </div>
            <div class="right-side clearfix">
                <ul>
                    <li><router-link class="item" to="/hr/cppTeacher" active-class="active">C++讲师</router-link></li>
                    <li><router-link class="item" to="/hr/testingTeacher" active-class="active">软件测试讲师</router-link></li>
                    <li><router-link class="item" to="/hr/testingTerminal" active-class="active">课程咨询老师</router-link></li>
                    <li><router-link class="item" to="/hr/testingEngineer" active-class="active">教务就业老师</router-link></li>
                </ul>
            </div>
            <router-view class="detail-container"></router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HumanResourceC',
}
</script>

<style scoped>
.banner {
    width: 100%px;
    height: 400px;
    background-color: #8EB7E2;
    /* background-image: url(../assets/img/humanResource/recruit.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%; */
}
.banner img {
    position: relative;
    top: -75px;
    width: 100%;
    height: 475px;
}
.main-content {
    width: 1200px;
    margin: 40px auto;
}
.left-side, .right-side {
    float: left;
}
.left-side {
    width: 30%;
    height: 800px;
    padding: 30px;
}
.left-side img { 
    width: 90%;
    margin: 0 auto;
}
.right-side {
    width: 70%;
    margin-bottom: 20px;
    background-color: #8EB7E2;
    color: white;
    border-radius: 10px;
    padding-left: 20px;
}
.right-side li {
    float: left;
    font-size: 18px;
    font-weight: 500;
    line-height: 50px;
}
.right-side li::after {
    content: '|';
    margin: 0 10px;
}
.item {
    color: white;
    font-weight: bold;
}
.active {
    color: #F57015;
}
.detail-container {
    color: #999;
    height: 800px;
}
</style>