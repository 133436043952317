<template>
  <div class="container">
        <div class="banner">
            <div class="wrapper">
            </div>
        </div>
        <div class="content clearfix">
            <div class="left-side">
                <img src="../assets/img/students/hardwork.png" alt="">
            </div>
            <div class="right-side">
                <div class="img">
                        <img src="../assets/img/students/1.jpg" alt="">
                </div>
                <div class="img">
                        <img src="../assets/img/students/2.jpg" alt="">
                </div>
                <div class="img">
                        <img src="../assets/img/students/3.jpg" alt="">
                </div>
                <div class="img">
                        <img src="../assets/img/students/4.jpg" alt="">
                </div>
                <div class="img">
                        <img src="../assets/img/students/5.jpg" alt="">
                </div>
                <div class="img">
                        <img src="../assets/img/students/6.jpg" alt="">
                </div>
                <div class="img">
                        <img src="../assets/img/students/17.jpeg" alt="">
                </div>
                <div class="img">
                        <img src="../assets/img/students/18.jpeg" alt="">
                </div>
                <div class="img">
                        <img src="../assets/img/students/19.jpeg" alt="">
                </div>
                <div class="img">
                        <img src="../assets/img/students/20.jpeg" alt="">
                </div>
        </div>
        </div>
  </div>
</template>

<script>
export default {
    name: 'StudentC',
}
</script>

<style scoped>
.banner {
    width: 100%px;
    height: 400px;
    background-color: #8EB7E2;
    background-image: url(../assets/img/students/students.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 60%;
}
.left-side {
    float: left;
    width: 30%;
    height: 800px;
    padding: 30px;
}
.left-side img {
    width: 90%;
    margin: 0 auto;
}
.right-side {
    width: 70%;
    margin: 20px auto;
    /* background-color: #8EB7E2; */
    color: white;
    border-radius: 10px;
    padding-left: 20px;
    float: right;
}
.right-side .img {
    display: inline-block;
    width: 30%;
    margin: 10px 10px;
    vertical-align: middle;
}
.right-side img {
    width: 100%;
    border-radius: 10%;
}
.right-side img:hover {
    /* border: 2px solid #8EB7E2; */
    /* box-sizing: border-box; */
    box-shadow: 0 0 20px rgba(142, 183, 226, 1);
}
</style>
