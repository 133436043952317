<template>
    <div  class="course-tab">
        <div class="course-main clearfix">
            <div class="title">C++课程大纲</div>
            <ul class="course-list-items">
                <li v-for="(item, index) in course_list" :key="index" @mouseenter="showContent(index)" :class="{'active-item':item.isActive,'inactive-item':!item.isActive}">
                    <p>{{item.title}}</p>
                    <p>{{item.desc}}</p>
                </li>
            </ul>
            <ul class="course-content-items">
                <transition name="content" v-for="(item, index) in course_content" :key="index" >
                    <li v-show="item.isActive" :appear="true">
                        <div class="task">课程内容</div>
                        <ul class="clearfix">
                            <li v-for="(task_item, task_index) in item.task" :key="task_index">{{task_item}}</li>
                        </ul>
                        <div class="target">学习目标</div>
                        <ul>
                            <li v-for="(target_item, target_index) in item.target" :key="target_index">{{target_item}}</li>
                        </ul>
                    </li>
                </transition>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeCourseTab',
    data() {
        return {
            course_list: [
                { title: "第一阶段", desc: "C++语言编程基础", isActive: true },
                { title: "第二阶段", desc: "数据结构与算法", isActive: false },
                { title: "第三阶段", desc: "C++新特性及Qt设计", isActive: false },
                { title: "第四阶段", desc: "C++高阶及项目实战", isActive: false },
                { title: "第五阶段", desc: "就业辅导", isActive: false },
            ],
            course_content: [
                {
                    task:[
                        'C++基础',
                        'OOP',
                        '异常和IO流',
                        'MySQL',
                        'Linux操作系统'
                    ],
                    target:[
                        '熟悉名字空间，各种数据类型操作',
                        '掌握C++各种异常情况处理，输入输出流操作',
                        '了解C++泛化数据类型技术原理和实现',
                        '熟练应用Linux的常用命令，熟悉Docker常用指令',
                        '掌握MySQL数据库基本操作'
                    ],
                    isActive: true
                },
                {
                    task:[
                        '数据结构和算法',
                        '标准STL模板库',
                        '设计模式',
                        '网络通信'
                    ],
                    target:[
                        '各种存储结构优劣对比，排序算法优劣对比',
                        '掌握STL 十大容器使用',
                        '掌握设计模式基本概念、原理、分类和应用场景',
                        '掌握网络通信的基本原理、协议、技术和应用'
                    ],
                    isActive: false
                },
                {
                    task:[
                        'C++新特性',
                        'Qt可视化UI设计',
                        'Linux下C++编程'
                    ],
                    target:[
                        '提升代码质量和效率，适应现代软件开发需求，提高程序性能',
                        '掌握QT 开源框架库的使用',
                        '更好地了解操作系统的底层原理，掌握更多的开发工具和技术',
                    ],
                    isActive: false
                },
                {
                    task:[
                        'C++高阶',
                        '典型工具',
                        '项目实战1',
                        '项目实战2'
                    ],
                    target:[
                        '掌握动态链接库的概念，创建和使用',
                        '掌握回调函数的概念及实现',
                        '了解一些常用编程辅助工具的用法',
                        '通过项目实战，全面掌握C++开发技术'
                    ],
                    isActive: false
                },
                {
                    task:[
                        '简历指导',
                        '模拟人事面试',
                        '模拟技术面试',
                    ],
                    target:[
                        '完成职业素养类课程，让学员成为一个职场专业人士',
                        '在就业老师的指导下，准备量身定制的简历',
                        '专业的人事面试指导以及模拟面试，让你熟悉面试流程',
                        '技术老师专业模拟技术面试'
                    ],
                    isActive: false
                },
            ]
        };
    },
    methods: {
        showContent(index){
            this.course_list.forEach((list) => { list.isActive = false; })
            this.course_list[index].isActive = true
            this.course_content.forEach((course) => { course.isActive = false; });
            this.course_content[index].isActive = true;
        }
    }
}
</script>

<style scoped>
.course-tab {
    background-color: #fff;
    padding: 80px 0;
}
.course-main {
    width: 1200px;
    margin: 0 auto;
}
.title {
    width: 1200px;
    padding: 10px 0;
    margin: 0 auto;
    padding-bottom: 80px;
    color: #616175;
    font-size: 36px;
    font-weight: bold;
    line-height: 48px;
    text-align: center;
}
.course-list-items{
    float: left;
    width: 20%;
    margin: 20px 20px 20px 12%;
    font-size: 20px;
    color: #fff;
}
.course-list-items li {
    margin: 10px 10px 20px 10px;
    padding-top: 5px;
    height: 80px;
    border-radius: 20px;
}
.inactive-item {
    background-color: #757779;
    box-shadow: 0 0 20px rgba(142, 183, 226, 0.5);
}
.course-list-items li:hover, .active-item {
    background-color: #3E71CC;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
}
.course-list-items:hover {
    cursor: pointer;
}
.course-list-items li p {
    text-align: center;
    color: #fff;
    line-height: 35px;
}
.course-content-items {
    float: left;
    width: 50%;
    height: 460px;
    margin: 40px 12% 20px 20px;
    padding: 30px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    position: relative;
    overflow: hidden;
}
.course-content-items .task, .course-content-items .target {
    font-size: 20px;
    font-weight: bold;
    color: #3E71CC;
}
.course-content-items ul {
    margin: 30px auto;
}
.course-content-items ul li {
    margin: 10px 20px;
}
.course-content-items li div {
    border-left: 5px solid #FE771B;
    padding-left: 10px;
}
.course-content-items ul:first-of-type li {
    float: left;
    margin: 10px 20px;
}
.course-content-items ul:last-of-type li {
    margin: 30px 20px;
}
.course-content-items ul:first-of-type {
    width: 550px;
}
.course-content-items ul:first-of-type li::before {
    content: '\f02b';
    font-family: FontAwesome;
    color: #FE771B;
    margin-right: 5px;
}
.course-content-items ul:last-child li::before {
    content: '\f0ae';
    font-family: FontAwesome;
    color: #FE771B;
    margin-right: 5px;
}
.course-content-items > li {
    position: absolute;
}
.hideContent {
    visibility:hidden;
    opacity:0;
    transform:translateX(200px);
    transition:.5s;
}
.showContent {
    visibility:visible;
    opacity:1;
    transform:translateX(0);
}
/* 动画效果 */
.content-enter-active {
    animation: pgy 0.3s linear;
}
/* 播放动画 - 去 - 注意，名字是Vue标准写法 */
/* .content-leave-active {
    animation: pgy 1s linear reverse;
} */

/* 定义动画 */
@keyframes pgy {
    from {
        transform: translateY(100%);
    }
    /* to {
        transform: translateY(0px);
    } */
}
</style>
