<template>
    <div  class="course-tab">
        <div class="course-main clearfix">
            <div class="title">测开课程大纲</div>
            <ul class="course-list-items">
                <li v-for="(item, index) in course_list" :key="index" @mouseenter="showContent(index)" :class="{'active-item':item.isActive,'inactive-item':!item.isActive}">
                    <p>{{item.title}}</p>
                    <p>{{item.desc}}</p>
                </li>
            </ul>
            <ul class="course-content-items">
                <transition name="content" v-for="(item, index) in course_content" :key="index" >
                    <li v-show="item.isActive" :appear="true">
                        <div class="task">课程内容</div>
                        <ul class="clearfix">
                            <li v-for="(task_item, task_index) in item.task" :key="task_index">{{task_item}}</li>
                        </ul>
                        <div class="target">学习目标</div>
                        <ul>
                            <li v-for="(target_item, target_index) in item.target" :key="target_index">{{target_item}}</li>
                        </ul>
                    </li>
                </transition>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeCourseTab',
    data() {
        return {
            course_list: [
                { title: "第一阶段", desc: "系统测试", isActive: true },
                { title: "第二阶段", desc: "测试开发", isActive: false },
                { title: "第三阶段", desc: "高级进阶", isActive: false },
                { title: "第四阶段", desc: "综合项目", isActive: false },
                { title: "第五阶段", desc: "就业阶段", isActive: false },
            ],
            course_content: [
                {
                    task:[
                        '测试基础',
                        'MySQL数据库',
                        'Linux操作系统',
                        '系统测试项目'
                    ],
                    target:[
                        '熟悉软件测试的标准流程规范',
                        '熟悉数据库相关体系知识',
                        '熟悉软件测试的设计，测试方法，熟练编写测试计划，测试报告，',
                        '熟练应用Linux的常用命令，熟悉Docker常用指令'
                    ],
                    isActive: true
                },
                {
                    task:[
                        'Python语言',
                        'UI自动化测试',
                        'Java语言',
                        '接口测试及Pytest测试框架'
                    ],
                    target:[
                        '熟悉Python基础语法、熟悉面向对象的核心概念',
                        '熟练编写UI自动化的测试脚本,独立完成指定项目的UI自动化测试任务',
                        '熟悉Java基础语法、',
                        '熟悉接口文档的格式以及根据接口文档提取测试要点',
                        '掌握Pytest框架的常用组件和应用,熟练使用requests模块',
                    ],
                    isActive: false
                },
                {
                    task:[
                        '测试框架开发',
                        '性能测试基础',
                        '高级性能测试',
                        '安全测试'
                    ],
                    target:[
                        '基于DDT和KDT实现自动化测试框架',
                        '熟悉性能测试的基本概念、独立完成指定项目的性能测试分析设计，执行，完成性能测试报告编写',
                        '理解安全测试的核心概念、运用所学知识完成指定项目的安全测试任务',
                    ],
                    isActive: false
                },
                {
                    task:[
                        '微服务项目实战',
                        'APP专项测试',
                        'APP自动化测试',
                        '自动化测试实战'
                    ],
                    target:[
                        '熟悉微服务架构系统的特点、理解微服务架构和单体架构的区别',
                        '熟悉APP专项测试的理论、方法，理解APP测试和WEB端测试的区别和相同点',
                        '熟悉Appiun框架的基本功能、环境搭建、真机连接、手势模拟等功能',
                        '复习自动化测试的实施流程规范，巩固自动化测试实施的核心能力，提高就业竞争力',
                    ],
                    isActive: false
                },
                {
                    task:[
                        '自选项目',
                        '就业辅导',
                    ],
                    target:[
                        '基于菩公瑛学苑的项目池继续拓展和积累实战项目经验',
                        '基于菩公瑛学苑的视频课程和直播课程完善知识体系',
                        '完成职业素养类课程，让学员成为一个职场专业人士',
                        '在就业老师的指导下，进行面试准备并去企业面试，最终完成就业',
                    ],
                    isActive: false
                },
            ]
        };
    },
    methods: {
        showContent(index){
            this.course_list.forEach((list) => { list.isActive = false; })
            this.course_list[index].isActive = true
            this.course_content.forEach((course) => { course.isActive = false; });
            this.course_content[index].isActive = true;
        }
    }
}
</script>

<style scoped>
.course-tab {
    background-color: #fff;
    padding: 80px 0;
}
.course-main {
    width: 1200px;
    margin: 0 auto;
}
.title {
    width: 1200px;
    padding: 10px 0;
    margin: 0 auto;
    padding-bottom: 80px;
    color: #616175;
    font-size: 36px;
    font-weight: bold;
    line-height: 48px;
    text-align: center;
}
.course-list-items{
    float: left;
    width: 20%;
    margin: 20px 20px 20px 12%;
    font-size: 20px;
    color: #fff;
}
.course-list-items li {
    margin: 10px 10px 20px 10px;
    padding-top: 5px;
    height: 80px;
    border-radius: 20px;
}
.inactive-item {
    background-color: #757779;
    box-shadow: 0 0 20px rgba(142, 183, 226, 0.5);
}
.course-list-items li:hover, .active-item {
    background-color: #3E71CC;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
}
.course-list-items:hover {
    cursor: pointer;
}
.course-list-items li p {
    text-align: center;
    color: #fff;
    line-height: 35px;
}
.course-content-items {
    float: left;
    width: 50%;
    height: 460px;
    margin: 40px 12% 20px 20px;
    padding: 30px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    position: relative;
    overflow: hidden;
}
.course-content-items .task, .course-content-items .target {
    font-size: 20px;
    font-weight: bold;
    color: #3E71CC;
}
.course-content-items ul {
    margin: 30px auto;
}
.course-content-items ul li {
    margin: 10px 20px;
}
.course-content-items li div {
    border-left: 5px solid #FE771B;
    padding-left: 10px;
}
.course-content-items ul:first-of-type li {
    float: left;
    margin: 10px 20px;
}
.course-content-items ul:last-of-type li {
    margin: 30px 20px;
}
.course-content-items ul:first-of-type {
    width: 550px;
}
.course-content-items ul:first-of-type li::before {
    content: '\f02b';
    font-family: FontAwesome;
    color: #FE771B;
    margin-right: 5px;
}
.course-content-items ul:last-child li::before {
    content: '\f0ae';
    font-family: FontAwesome;
    color: #FE771B;
    margin-right: 5px;
}
.course-content-items > li {
    position: absolute;
}
.hideContent {
    visibility:hidden;
    opacity:0;
    transform:translateX(200px);
    transition:.5s;
}
.showContent {
    visibility:visible;
    opacity:1;
    transform:translateX(0);
}
/* 动画效果 */
.content-enter-active {
    animation: pgy 0.3s linear;
}
/* 播放动画 - 去 - 注意，名字是Vue标准写法 */
/* .content-leave-active {
    animation: pgy 1s linear reverse;
} */

/* 定义动画 */
@keyframes pgy {
    from {
        transform: translateY(100%);
    }
    /* to {
        transform: translateY(0px);
    } */
}
</style>
