<template>
   <div class="tools-tab">
        <div class="tools-main clearfix">
            <h1 class="title">C++开发工具</h1>
            <ul class="tools clearfix">
                <li class="tool"><img src="../assets/img/homecpp/tools/tool1.png" width="100" height="100"><span class="desc" >VSCode</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool2.png" width="100" height="100"><span class="desc" >gcc</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool3.jpg" width="100" height="100"><span class="desc" >g++</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool4.gif" width="100" height="100"><span class="desc" >QT</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool5.png" width="100" height="100"><span class="desc" >QTCreater</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool6.jpg" width="100" height="100"><span class="desc" >Linux</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool7.png" width="100" height="100"><span class="desc" >CentOS</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool8.png" width="100" height="100"><span class="desc" >VMWare</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool9.jpg" width="100" height="100"><span class="desc" >Xshell</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool10.png" width="100" height="100"><span class="desc" >Xftp</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool11.jpg" width="100" height="100"><span class="desc" >MySQL</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool12.png" width="100" height="100"><span class="desc" >Sqlyog</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool13.png" width="100" height="100"><span class="desc" >Redis</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool14.png" width="100" height="100"><span class="desc" >Navicat</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool15.png" width="100" height="100"><span class="desc" >Zentao</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool16.jpg" width="100" height="100"><span class="desc" >Xmind</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool17.png" width="100" height="100"><span class="desc" >Git</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool18.jpg" width="100" height="100"><span class="desc" >SVN</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool19.png" width="100" height="100"><span class="desc" >Vim</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool20.png" width="100" height="100"><span class="desc" >Fiddler</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool21.png" width="100" height="100"><span class="desc" >SoapUI</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool22.png" width="100" height="100"><span class="desc" >nmon</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool23.png" width="100" height="100"><span class="desc" >Docker</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool24.png" width="100" height="100"><span class="desc" >Testlink</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool25.png" width="100" height="100"><span class="desc" >HttpWatch</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool26.png" width="100" height="100"><span class="desc" >Sonar</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool27.jpg" width="100" height="100"><span class="desc" >Visual Studio</span></li>
                <li class="tool"><img src="../assets/img/homecpp/tools/tool28.png" width="100" height="100"><span class="desc" >SourceInsight</span></li>
            </ul>
        </div>
   </div>
</template>

<script>
export default {
    name: 'HomeToolsTab',
}
</script>

<style scoped>
.tools-tab {
    background-color: #F7F7F7;
    padding: 150px 0;
}
.tools-main {
    width: 1200px;
    margin: 0 auto;
}
.title {
    width: 1200px;
    padding: 10px 0;
    margin: 0 auto;
    padding-bottom: 50px;
    color: #616175;
    font-size: 36px;
    font-weight: bold;
    line-height: 48px;
    text-align: center;
}
.tools {
    width: 750px;
    margin: 20px auto;
}
.tools .tool {
    float: left;
    margin: 20px;
}
.tool img,.desc {
    display: block;
    margin: 5px auto;
    text-align: center;
}
.tool img {
    border-radius: 10px;
}
.desc {
    width: 110px;
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #616175;
}
.tool img {
    box-shadow: 0 0 20px rgba(142, 183, 226, 0.4);
}
.tool img:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}
.tool img:hover + .desc {
    font-weight: bold;
    color: #0671CD;
}
</style>
