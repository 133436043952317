import Vue from 'vue'

/* Element-UI */

// 引入element-ui
import ElementUI from 'element-ui'

// 引入全部样式
import 'element-ui/lib/theme-chalk/index.css'

// 使用elemnt-ui
Vue.use(ElementUI);

// 引入弹框提示组件
// import { Message } from 'element-ui'
// 挂载弹窗提示组件
Vue.prototype.$message = ElementUI.Message
