<template>
    <div class="main">
        <div class="banner clearfix">
            <transition name="background" v-for="(item, index) in imgs" :key="index" >
                <img class="img" :src="item.url" :class="{'show-img': item.isActive}">
            </transition>
            <div class="pagination">
                <span class="swiper-pagination-switch" v-for="(span, index) in spans" :key="index" 
                    :class="{'swiper-active-switch':span.isActive}" @click="active_span(index)"></span>
            </div>
            <div class="prev-next">
                <a class="prev" href="javascript:;" @click="prev_img"><i class="fa fa-angle-left"></i></a>
                <a class="next" href="javascript:;" @click="next_img"><i class="fa fa-angle-right"></i></a>
            </div>
        </div>
        <!-- 菩公瑛优势 -->
        <div>
            <HomeAdvantageTab/>
        </div>
        <!-- 侧开行业前景 -->
        <div>
            <HomeAnalysisTab/>
        </div>
        <!-- 课程大纲 -->
        <div>
            <HomeCourseTab/>
        </div>
        <!-- 测试工具 -->
        <div>
            <HomeToolsTab/>
        </div>
        <!-- 项目案例 -->
        <div>
            <HomeProjectTab/>
        </div>
        <!-- 教师团队 -->
        <div>
            <HomeTeacherTab/>
        </div>
    </div>
</template>

<script>
import HomeCourseTab from './HomeCourseTab.vue'
import HomeAdvantageTab from './HomeAdvantageTab.vue'
import HomeAnalysisTab from './HomeAnalysisTab.vue';
import HomeProjectTab from './HomeProjectTab.vue';
import HomeTeacherTab from './HomeTeacherTab.vue';
import HomeToolsTab from './HomeToolsTab.vue';

export default {
    name: "HomeC",
    components: { HomeCourseTab, HomeAdvantageTab, HomeAnalysisTab, HomeProjectTab, HomeTeacherTab, HomeToolsTab },
    data(){
        return {
            imgs: [
                {url: require('@/assets/img/home/pgy1.jpg'), isActive: true},
                {url: require('@/assets/img/home/pgy2.jpg'), isActive: false},
                {url: require('@/assets/img/home/pgy3.jpg'), isActive: false},
            ],
            spans: [
                {isActive:true},
                {isActive:false},
                {isActive:false},
            ],
        }
    },
    methods: {
        prev_img(){
            for(let i=0;i<this.imgs.length;i++){
                if(this.imgs[i].isActive){
                    // 隐藏当前图片
                    this.imgs[i].isActive = false;
                    // 显示上一张图片
                    this.imgs[(i-1+3)%3].isActive = true;
                    // 更新指示标志
                    this.spans[i].isActive = false;
                    this.spans[(i-1+3)%3].isActive = true;
                    break;
                }
            }
        },
        next_img(){
            for(let i=0;i<this.imgs.length;i++){
                if(this.imgs[i].isActive){
                    // 隐藏当前图片
                    this.imgs[i].isActive = false;
                    // 显示上一张图片
                    this.imgs[(i+1)%3].isActive = true;
                    // 更新指示标志
                    this.spans[i].isActive = false;
                    this.spans[(i+1)%3].isActive = true;
                    break;
                }
            }
        },
        active_span(index){
            this.imgs.forEach((img) => { img.isActive = false; });
            this.imgs[index].isActive = true;
            this.spans.forEach((span) => { span.isActive = false; });
            this.spans[index].isActive = true;
        }
    },
    mounted() {
        let index = 0;
        window.setInterval(() => {
            index++;
            this.imgs.forEach((img) => { img.isActive = false; });
            this.imgs[index % this.imgs.length].isActive = true;
            this.spans.forEach((span) => { span.isActive = false; });
            this.spans[index % this.spans.length].isActive = true;
        }, 4000);
    },
}
</script>

<style scoped>
.main {
    min-width: 1200px;
}
/* 头部 */
.banner {
    width: 100%;
    position: relative;
}
.img {
    display: none;
    width: 100%;
    position: relative;
    top: -75px;
    z-index: 1;
}
.show-img {
    display: block;
}
/* 左右的箭头 */
.prev-next a{
    width: 60px;
    height: 10px;
    font-size: 5rem;
    color: #858391;
    /* background-image: url(../assets/img/home/icon-slides.png); */
    position: absolute;
    /* 垂直居中 */
    top:0;
    bottom:0;
    margin: auto 0;
    z-index: 999;
}
.prev-next .prev {
    left: 10%;
}
.prev-next .prev:hover {
    color: white;
}
.prev-next .next {
    right: 10%;
}
.prev-next .next:hover {
    color: white;
}
/* 当前图片指示标志 */
.pagination {
    text-align: center;
    position: absolute;
    bottom: 20%;
    left: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    height: 10%;
}
.swiper-pagination-switch {
    display: inline-block;
    width: 5%;
    height: 10%;
    background: #555;
    margin: 0 5px;
    opacity: 0.8;
    cursor: pointer;
}
.swiper-active-switch {
    background: #fff;
}
</style>