import Vue from 'vue'
// 引入vue-router
import VueRouter from 'vue-router'
// 应用路由
Vue.use(VueRouter)
// 引入组件
import HomecppCVue from '@/components/HomecppC.vue'
import HomeCVue from '@/components/HomeC.vue'
import CourseCVue from '@/components/CourseC.vue'
import TeacherCVue from '@/components/TeacherC.vue'
import HumanResourceCVue from '@/components/HumanResourceC.vue'
import HumanResourceCppTeacherCVue from '@/components/HumanResourceCppTeacherC.vue'
import HumanResourceTestingTeacherCVue from '@/components/HumanResourceTestingTeacherC.vue'
import HumanResourceTestingTerminalCVue from '@/components/HumanResourceTestingTerminalC.vue'
import HumanResourceTestingEngineerCVue from '@/components/HumanResourceTestingEngineerC.vue'
import StudentCVue from '@/components/StudentC.vue'
import AboutCVue from '@/components/AboutC.vue'
import FreeCourseCVue from "@/components/FreeCourseC";

// 创建一个路由器
const router = new VueRouter({
    routes: [
        {path: '/', redirect: '/home'},
        {path: '/home', component: HomecppCVue},
        {path: '/hometest', component: HomeCVue},
        {path: '/course', component: CourseCVue},
        {path: '/teacher', component: TeacherCVue},
        {path: '/free_course', component: FreeCourseCVue},
        {path: '/student', component: StudentCVue},
        {path: '/about', component: AboutCVue,},
        {
            path: '/hr',
            component: HumanResourceCVue,
            redirect:'/hr/cppTeacher',
            children: [
                {
                    path: 'cppTeacher',
                    component: HumanResourceCppTeacherCVue
                },
                {
                    path: 'testingTeacher',
                    component: HumanResourceTestingTeacherCVue
                },
                {
                    path: 'testingTerminal',
                    component: HumanResourceTestingTerminalCVue
                },
                {
                    path: 'testingEngineer',
                    component: HumanResourceTestingEngineerCVue
                },
            ],
        }
    ]
})

export default router
